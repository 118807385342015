import React from 'react'

const LandingPage = () => {
    return (
        <>
            <div class="page_wrapper" id='home'>
                <section class="hero_banner_section d-flex align-items-center ">
                    <div class="container" >
                        <div class="hero_banner_inner">
                            <div class="row align-items-center">
                                <div class="col-12 col-lg-6">
                                    <div class="hero_content">
                                        <h1 class="wow fadeInUp" data-wow-delay="0.1s" data-wow-duration="1.1s">Play Gitti and Win Exciting Cash Prices..</h1>
                                        <p class="wow fadeInUp" data-wow-delay="0.2s" data-wow-duration="1.2s">Download The India's Best, 100% Safe, Secure Gitti App and play against Real-Time Players and Earn Real Money.</p>
                                        <a class="btn btn-link wow fadeInUp" data-wow-delay="0.3s" data-wow-duration="1.3s" target="_blank"
                                            href="https://api.gitty.online/downloads"
                                        >Download App</a>
                                    </div>
                                </div>
                                <div class="col-12 col-lg-6" >
                                    <div class="hero_position_img">
                                        <img width="785" height="785" src="assets/img/bg/hero-position-img.svg" className='' alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </section>

                {/* <section class="gaming_video_section bg-black ">
                    <div class="container">
                        <div class="row">
                            <div class="col-12">
                                <div class="gaming_video_inner slick_navigation slick__activation"
                                >
                                    <div class="gaming_video_thumb">
                                        
                                            <img src="assets/img/poster/poster-1.png" class="img-fluid" alt="" />


                                    </div>
                                    <div class="gaming_video_thumb">
                                        
                                            <img class="img-fluid" src="assets/img/poster/poster-2.png" alt="" />

                                       

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section> */}

                <section class="gaming_world_section mb-140   " id="aboutus">
                    <div class="container">
                        <div class="section_title text-center wow fadeInUp mb-60" data-wow-delay="0.1s" data-wow-duration="1.1s">
                            <h3 class="text_pink" > About Us</h3>
                            <h2>App Features</h2>
                            <p>Gitti Powered by Zumbo Tech Private Limited, We have tried our best that Players should have in a Real Money Games & keeping the game fair. We always keep our Players's security on priority and the other hand providing 24*7 support.</p>
                        </div>
                        <div class="gaming_world_inner">
                            <div class="row">
                                <div class="col-lg-4 col-md-4 col-sm-6">
                                    <div class="single_gaming_world wow fadeInUp  single_gaming_world_1" >
                                        <div class="gaming_world_thumb">
                                            <img width="141" height="157" src="assets/img/others/gaming-world1.webp" alt="" />
                                        </div>
                                        <div class="gaming_world_text">
                                            <h3>EASY TO PLAY</h3>
                                            <p>Learn how to play Ludo with simple rules, strategy tips, and tricks to win. Have fun with this classic board game!</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-4 col-sm-6">
                                    <div class="single_gaming_world wow fadeInUp single_gaming_world_2"  >
                                        <div class="gaming_world_thumb">
                                            <img width="156" height="157" src="assets/img/others/gaming-world2.webp" alt="" />
                                        </div>
                                        <div class="gaming_world_text">
                                            <h3>PLAY WITH COMPUTERS</h3>
                                            <p>Challenge computer opponents in Ludo and test your skills. Play against AI and enjoy a competitive gaming experience.</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-4 col-sm-6">
                                    <div class="single_gaming_world wow fadeInUp single_gaming_world_3" >
                                        <div class="gaming_world_thumb">
                                            <img width="151" height="156" src="assets/img/others/gaming-world3.webp" alt="" />
                                        </div>
                                        <div class="gaming_world_text">
                                            <h3>PLAY WITH LOCAL</h3>
                                            <p>Gather friends and family for a fun-filled Ludo game night. Play together locally and enjoy some friendly competition. </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section class="upcoming_gaming_section mb-125" id="howtoplay">
                    <div class="container">
                        <div class="section_title text-center wow fadeInUp mb-60" data-wow-delay="0.1s" data-wow-duration="1.1s">
                            <h2>how can you play</h2>
                        </div>
                        <div class="upcoming_gaming_inner">
                            <div class="row justify-content-center mb-30-none">
                                <div class="col-6 col-md-3">
                                    <div class="how-item-2">
                                        <div class="how-thumb"><span>01</span></div>
                                        <h5 class="title">Sign Up</h5>
                                    </div>
                                </div>
                                <div class="col-6 col-md-3">
                                    <div class="how-item-2">
                                        <div class="how-thumb"><span>02</span></div>
                                        <h5 class="title">Choose game</h5>
                                    </div>
                                </div>
                                <div class="col-6 col-md-3">
                                    <div class="how-item-2">
                                        <div class="how-thumb"><span>03</span></div>
                                        <h5 class="title">get bonous</h5>
                                    </div>
                                </div>
                                <div class="col-6 col-md-3">
                                    <div class="how-item-2">
                                        <div class="how-thumb"><span>04</span></div>
                                        <h5 class="title">withdraw</h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section id="screenshots" class="testimonial_section">
                    <div class="container">
                        <div class="row">
                            <div class="col-12">
                                <div class="section_title mb-60">
                                    <h2>GAME INTERFACE<br /> Looks <span> Awesome </span> </h2>
                                </div>
                                <div class="slick_navigation testimonial_inner screens_slider"   >
                                    <img src="/assets/img/screens/app_scrn1.png" class="img-fluid" alt='' />
                                    <img src="/assets/img/screens/app_scrn2.png" class="img-fluid" alt='' />
                                    <img src="/assets/img/screens/app_scrn3.png" class="img-fluid" alt='' />
                                    <img src="/assets/img/screens/app_scrn4.png" class="img-fluid" alt='' />
                                    <img src="/assets/img/screens/app_scrn5.png" class="img-fluid" alt='' />
                                    <img src="/assets/img/screens/app_scrn6.png" class="img-fluid" alt='' />
                                    <img src="/assets/img/screens/app_scrn7.png" class="img-fluid" alt='' />
                                    <img src="/assets/img/screens/app_scrn8.png" class="img-fluid" alt='' />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section id="download" class="gaming_update_section download_sec"  >
                    <div class="container">
                        <div class="row">
                            <div class="col-12">
                                <div class="gaming_update_inner d-flex justify-content-between align-items-center" >
                                    <div class="gaming_update_text">
                                        <h2>Download mobile app</h2>
                                        <hr />
                                        <div class="down_tab"><div>
                                            {/* <a href="https://bunzzi-assets.s3.ap-south-1.amazonaws.com/VO.O.29-Release-02May-Bunzzi.apk"> */}
                                            <img src="assets/img/play_store.png" class="img-fluid" />
                                            {/* </a> */}
                                            {/* <a target="_blank" href="https://bunzzi-assets.s3.ap-south-1.amazonaws.com/VO.O.29-Release-02May-Bunzzi.apk"> */}
                                            <img src="assets/img/app_store.png" class="img-fluid" />
                                            {/* </a> */}
                                        </div>
                                            <div class=" ms-3"><img src="assets/img/bunzi_app_download.png" width="140" className="img-fluid" alt="" /></div></div>
                                    </div>
                                    <div class="gaming_update_btn">
                                        <img src="assets/img/ludo_img.png" class="img-fluid" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section >

                <section id="faq" class="popular_gaming_section faq_sec mb-140 ">
                    <div class="container">
                        <div class="section_title text-center mb-60" >
                            <h3 class="text_pink">HOW CAN WE HELP?</h3>
                            <h2>Frequently Asked Questions</h2>
                            <p>Find answers to common Ludo game questions. Learn about rules, setup, gameplay, and strategies in our comprehensive FAQ section.</p>
                        </div>
                        <div class="row align-items-center" >
                            <div class="col-lg-6" >
                                <div class="faq_page_inner">
                                    <div class="accordion" id="accordionExample">
                                        <div class="faq_question_list">
                                            <h2 class="questions__title accordion-header" id="headingOne">
                                                <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                                    DOWNLOAD AND INSTALL ?
                                                </button>
                                            </h2>
                                            <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                                <div class="frequently_desc accordion-body">
                                                    <p>Download Our Gitti Free android application and login with Google</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="faq_question_list">
                                            <h2 class="questions__title accordion-header" id="headingTwo">
                                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                                    How to Play?
                                                </button>
                                            </h2>
                                            <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                                                <div class="frequently_desc accordion-body">
                                                    <p>Play Game from your Deposit balance, Upon matching player plays, Gitti Room id will be shared, You both play Game in Gitti.</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="faq_question_list">
                                            <h2 class="questions__title accordion-header" id="headingthree">
                                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapsethree" aria-expanded="false" aria-controls="collapsethree">
                                                    How to withdraw cash?
                                                </button>
                                            </h2>
                                            <div id="collapsethree" class="accordion-collapse collapse" aria-labelledby="headingthree" data-bs-parent="#accordionExample">
                                                <div class="frequently_desc accordion-body">
                                                    <p>Withdraw Winnings amount to your bank account, Paytm Wallet or UPI ID instantly.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-6 text-center my-3" >
                                <img src="assets/img/faq.png" />
                            </div>

                            <div class="col-md-12 text-center" >
                                <p class="mt-5">Do not hesitate to <a class="text_pink" >contact us</a>  if you have any questions  <br /> We're here to help and don’t forget to check the <a class="text_pink" >FAQ page.</a></p>
                            </div>
                        </div>
                    </div>
                </section>







            </div >
        </>
    )
}

export default LandingPage
